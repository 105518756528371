<template>
  <div>
    <protocol :pageTitle="pageTitle" :protocolType="protocolType"></protocol>
  </div>
</template>
<script>
import Protocol from "@/components/Protocol";
export default {
  name: "protocol_privacy",
  components: {
    Protocol
  },
  data() {
    return {
      pageTitle: "隐私政策",
      protocolType: 1,
    };
  },
};
</script>
